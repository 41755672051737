import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { SmallTitle } from "./Whatis";

import Rango from "../../../assets/rango.svg";
import ShapeShift from "../../../assets/shapeshift.svg";
import ThorSwap from "../../../assets/thorswap.svg";
import Thorwallet from "../../../assets/thorwallet.svg";
import TrustWallet from "../../../assets/trust.svg";
import XDefi from "../../../assets/xdefi.svg";

function Providing() {
	return (
		<>
			<Container>
				<Box>
					<SmallTitle text="PROVIDING INFRASTRUCTURE FOR" />
				</Box>

				<Box
					mt={5}
					sx={{
						display: "grid",
						gridTemplateColumns: { xs: "repeat(1, 1fr)", md: "repeat(3, 1fr)" },
						gap: 4,
						justifyContent: "center",
						width: "100%",
					}}>
					{data.map((item, index) => (
						<StackBox key={index} text={item.text} value={item.value} />
					))}
				</Box>
			</Container>
		</>
	);
}

export default Providing;

const StackBox = ({ value }) => (
	<Box>
		<img src={value} alt="" />
	</Box>
);

const data = [
	{ value: TrustWallet },
	{ value: ThorSwap },
	{ value: ShapeShift },
	{ value: XDefi },
	{ value: Thorwallet },
	{ value: Rango },
];
