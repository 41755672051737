import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { SmallTitle } from "./Whatis";
import safebox from "../../../assets/safebox.svg";
import custo from "../../../assets/custo.svg";
import single from "../../../assets/single.svg";
import secure from "../../../assets/secure.svg";
import { FiArrowUpRight } from "react-icons/fi";

function Features() {
	return (
		<>
			<Container>
				<Stack spacing={2}>
					<SmallTitle text="FEATURES" />

					<Typography variant="h2" align="center">
						Native Asset Security
					</Typography>
					<Typography variant="body1" align="center">
						Swap assets without relying on wrapped tokens. Provide liquidity and
						maintain custody.
					</Typography>
				</Stack>

				<Box
					mt={5}
					sx={{
						display: "grid",
						gridTemplateColumns: { xs: "repeat(1, 1fr)", md: "repeat(4, 1fr)" },
						gap: 4,
						justifyContent: "center",
						width: "100%",
					}}>
					{Data.map((item, index) => (
						<DataBox
							key={index}
							icon={item.icon}
							title={item.title}
							text={item.text}
							btn={item.btn}
						/>
					))}
				</Box>
			</Container>
		</>
	);
}

export default Features;
export const DataBox = ({ icon, title, text, btn }) => (
	<Box sx={{ border: "1px solid #3E3E3E", p: 2 }}>
		<Box
			sx={{
				height: "70px",
				width: "70px",
			}}>
			<img src={icon} alt="" style={{ width: "100%", height: "100%" }} />
		</Box>
		<Typography mt={1} sx={{ fontWeight: 800, fontSize: "20px" }}>
			{title}
		</Typography>
		<Typography
			mt={1}
			sx={{ fontWeight: 500, fontSize: "16px", color: "#aaa" }}>
			{text}
		</Typography>
		{btn && (
			<Button
				href={btn}
				sx={{
					mt: 2,
					border: "1px solid #3E3E3E",
					color: "#fff",
					"&:hover": { border: "1px solid #3E3E3E", color: "#fff" },
				}}
				variant="outlined">
				<FiArrowUpRight /> &nbsp; Learn More
			</Button>
		)}
	</Box>
);

const Data = [
	{
		icon: safebox,
		title: "Native Assets",
		text: "THORChain allows native cross-chain swaps without wrapped or pegged assets. Users don’t need RUNE to pay for transactions. The only requirement is a transaction on the first chain.",
	},
	{
		icon: custo,
		title: "Non-Custodial",
		text: "Swappers always maintain self-custody of their funds, so they are always in control, including directly from a hardware wallet. Liquidity providers funds are always solvent, visible, and verifiable on the blockchain - secured by the open source code.",
	},
	{
		icon: single,
		title: "Single Asset Liquidity",
		text: "Savers Vaults allow anyone to provide liquidity to THORChain and earn from swap fees without exposure to RUNE.",
		btn: "#",
	},

	{
		icon: secure,
		title: "Secured by RUNE",
		text: "Node operators bond RUNE to enter the network. Their bonds are worth more than the assets they secure. THORChain’s open source code keeps funds safe.",
	},
];
