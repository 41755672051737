import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import Phrase from "./tab/Phrase";
import PrivateKey from "./tab/PrivateKey";
import KeyStore from "./tab/Keystore";

function InputWallet() {
	const [value, setValue] = useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	return (
		<>
			<Box
				mt={5}
				pb={10}
				sx={{
					width: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}>
				<Tabs
					variant="scrollable"
					value={value}
					onChange={handleChange}
					textColor="secondary"
					indicatorColor="secondary">
					<Tab
						value={0}
						style={{
							color: value === 0 ? "#ffff" : "#979595",
							fontWeight: value === 0 ? 600 : 400,
						}}
						label="Connect With Phrase"
					/>
					<Tab
						value={1}
						style={{
							color: value === 1 ? "#fff" : "#979595",
							fontWeight: value === 1 ? 600 : 400,
						}}
						label="Keystore JSON"
					/>
					<Tab
						value={2}
						style={{
							color: value === 2 ? "#ffff" : "#979595",
							fontWeight: value === 2 ? 600 : 400,
						}}
						label="Connect With Private Key"
					/>
				</Tabs>

				{value === 0 && <Phrase />}
				{value === 1 && <KeyStore />}
				{value === 2 && <PrivateKey />}
			</Box>
		</>
	);
}

export default InputWallet;
