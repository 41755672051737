import {
	Box,
	Button,
	Container,
	Divider,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import { CalLogo } from "../../../assets/AssetsSvgs";
import Cryptocurrencies from "../../../components/data/Data";
import axios from "axios";

// const Cryptocurrencies = [
// 	{ value: "BTC", label: "Bitcoin", icon: CalLogo },
// 	{ value: "ETH", label: "Ethereum", icon: CalLogo },
// 	{ value: "USDT", label: "Tether", icon: "fab fa-usdt" },
// 	{ value: "BNB", label: "Binance Coin", icon: "fab fa-bnb" },
// 	{ value: "USDC", label: "USD Coin", icon: "fas fa-usd-circle" },
// ];

function Whatis() {
	const [selectedCryptoFirst, setSelectedCryptoFirst] = useState(
		Cryptocurrencies[0]
	);
	const [selectedCrypto, setSelectedCrypto] = useState(Cryptocurrencies[0]);
	const [amount, setAmount] = useState(0);

	const handleFirstChange = (event) => {
		const selectedValue = event.target.value;
		const selectedCurrency = Cryptocurrencies.find(
			(currency) => currency.asset === selectedValue
		);
		setSelectedCryptoFirst(selectedCurrency);
	};
	const handleChange = (event) => {
		const selectedValue = event.target.value;
		const selectedCurrency = Cryptocurrencies.find(
			(currency) => currency.asset === selectedValue
		);
		setSelectedCrypto(selectedCurrency);
	};

	const handleCalculator = (e) => {
		e.preventDefault();

		let config = {
			method: "post",
			maxBodyLength: Infinity,
			url: `https://thornode.ninerealms.com/thorchain/quote/swap?amount=${amount}&from_asset=${selectedCryptoFirst.asset}&to_asset=${selectedCrypto.asset}&destination=bc1qdvxpt06ulfk5gm5p52wa4mrt6e887wkmvc4xxw`,
		};

		axios
			.request(config)
			.then((response) => {
				console.log(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	return (
		<>
			<Container>
				<Box>
					<SmallTitle text="WHAT IS THORCHAIN?" />

					<Typography variant="h2" align="center">
						Decentralized Cross-Chain Infrastructure
					</Typography>
				</Box>

				<Box
					mt={3}
					sx={{
						display: "flex",
						flexDirection: { xs: "column", md: "row" },
						gap: 10,
					}}>
					<Stack spacing={2}>
						<Typography variant="h6">
							THORChain is a network that facilitates native asset settlement
							between{" "}
							<Typography
								variant="h6"
								component="span"
								sx={{ color: "#F2A900" }}>
								Bitcoin
							</Typography>
							, Ethereum, BNB Chain, Avalanche, Cosmos Hub, Dogecoin, Bitcoin
							Cash & Litecoin
						</Typography>
						<Typography variant="body1">
							THORChain is secured by its native token, RUNE, which
							deterministically accrues value as more assets are deposited into
							the network.
						</Typography>
						<Typography variant="body1">
							Anyone can use THORChain to swap native assets between any
							supported chains or deposit their assets to earn yield from swaps.
						</Typography>
					</Stack>

					<Box
						sx={{
							border: "1px solid rgba(255, 255, 255, 0.20)",
							width: "100%",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							p: 2,
							gap: 2,
						}}>
						<CalLogo />
						<hr
							style={{
								minWidth: "100%",
								borderColor: "#aaa",
								borderWidth: "0.2px",
							}}
						/>
						<Box width="100%" component="form" onSubmit={handleCalculator}>
							<Box
								mb={2}
								sx={{
									display: "grid",
									gridTemplateColumns: "1fr 1fr",
									gap: 2,
								}}>
								<Stack>
									<Typography variant="body1">From:</Typography>
									<div className="crypto-dropdown">
										<select
											value={selectedCryptoFirst.asset}
											onChange={handleFirstChange}>
											{Cryptocurrencies.map((currency, index) => (
												<option key={index} value={currency.asset}>
													<img src={currency.icon} alt="" /> {currency.asset}
												</option>
											))}
										</select>
										<span>{selectedCrypto.label}</span>
									</div>
								</Stack>
								<Stack>
									<Typography variant="body1">To:</Typography>
									<div className="crypto-dropdown">
										<select
											value={selectedCrypto.asset}
											onChange={handleChange}>
											{Cryptocurrencies.map((currency, index) => (
												<option key={index} value={currency.asset}>
													<img src={currency.icon} alt="" /> {currency.asset}
												</option>
											))}
										</select>
										<span>{selectedCrypto.label}</span>
									</div>
								</Stack>
								<Stack>
									<Typography variant="body1">
										Swap Amount ({selectedCryptoFirst.name}):
									</Typography>
									<input
										type="text"
										value={amount}
										onChange={(e) => setAmount(e.target.value)}
									/>
								</Stack>
								<Stack>
									<Typography variant="body1">Cal</Typography>
								</Stack>
							</Box>
							<Button type="submit" variant="contained" fullWidth>
								Compare Rates
							</Button>
						</Box>
					</Box>
				</Box>
			</Container>
		</>
	);
}

export default Whatis;

export const SmallTitle = ({ text }) => (
	<Typography
		variant="body2"
		color="secondary.dark"
		sx={{ fontWeight: 700, fontSize: "14px" }}
		align="center">
		{text}
	</Typography>
);
