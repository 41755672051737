const Cryptocurrencies = [
	{
		annualPercentageRate: "-9.079199379006923",
		name: "AVAX",
		asset: "AVAX.AVAX",
		assetDepth: "14925771060633",
		assetPrice: "6.522754925200099",
		assetPriceUSD: "36.00717277398419",
		liquidityUnits: "33043990847849",
		nativeDecimal: "18",
		poolAPY: "0",
		runeDepth: "97357146698153",
		saversAPR: "0.02597130163519209",
		saversDepth: "19166252705332",
		saversUnits: "17722964097264",
		status: "available",
		synthSupply: "19169069936150",
		synthUnits: "59295503939104",
		totalCollateral: "0",
		totalDebtTor: "0",
		units: "92339494786953",
		volume24h: "53489834794716",
	},
	{
		annualPercentageRate: "-0.7081736058664229",
		asset: "BCH.BCH",
		name: "BCH",
		assetDepth: "1139455553628",
		assetPrice: "41.50966944263857",
		assetPriceUSD: "229.1433384439486",
		liquidityUnits: "16331202154531",
		nativeDecimal: "8",
		poolAPY: "0",
		runeDepth: "47298423375677",
		saversAPR: "0.0677189286623286",
		saversDepth: "730856855671",
		saversUnits: "684027613881",
		status: "available",
		synthSupply: "792722942555",
		synthUnits: "8710955271269",
		totalCollateral: "0",
		totalDebtTor: "0",
		units: "25042157425800",
		volume24h: "4072482066131",
	},

	{
		annualPercentageRate: "-0.1254177106020186",
		asset: "BNB.BNB",
		name: "BNB",
		assetDepth: "2686732220641",
		assetPrice: "45.46542657039661",
		assetPriceUSD: "250.98006724711357",
		liquidityUnits: "66096659242711",
		nativeDecimal: "8",
		poolAPY: "0",
		runeDepth: "122153426491872",
		saversAPR: "0.11938555645199578",
		saversDepth: "405058894346",
		saversUnits: "374727485503",
		status: "available",
		synthSupply: "429478010432",
		synthUnits: "5741735359674",
		totalCollateral: "0",
		totalDebtTor: "0",
		units: "71838394602385",
		volume24h: "91191049658514",
	},

	{
		annualPercentageRate: "-1.5694342846692806",
		asset: "BTC.BTC",
		name: "BTC",
		assetDepth: "152608349877",
		assetPrice: "7478.7481056606275",
		assetPriceUSD: "41284.48458691221",
		liquidityUnits: "427587700454731",
		nativeDecimal: "8",
		poolAPY: "0",
		runeDepth: "1141319407550608",
		saversAPR: "0.028979758495703358",
		saversDepth: "87334512357",
		saversUnits: "84472280363",
		status: "available",
		synthSupply: "113460993739",
		synthUnits: "253001730234680",
		totalCollateral: "35853631924",
		totalDebtTor: "413027196691400",
		units: "680589430689411",
		volume24h: "654632636097796",
	},
	{
		annualPercentageRate: "-0.7423777331496109",
		asset: "DOGE.DOGE",
		name: "DOGE",
		assetDepth: "3556816025885713",
		assetPrice: "0.016846316588321433",
		assetPriceUSD: "0.09299571100815464",
		liquidityUnits: "28226755852309",
		nativeDecimal: "8",
		poolAPY: "0",
		runeDepth: "59919248818486",
		saversAPR: "0.14310726005859514",
		saversDepth: "1680289616309893",
		saversUnits: "1554347531849290",
		status: "available",
		synthSupply: "1798434705784037",
		synthUnits: "9550722965123",
		totalCollateral: "0",
		totalDebtTor: "0",
		units: "37777478817432",
		volume24h: "18934797242475",
	},

	{
		annualPercentageRate: "-1.0493540818814435",
		asset: "ETH.ETH",
		name: "ETH",
		assetDepth: "1557953151169",
		assetPrice: "395.50841164299493",
		assetPriceUSD: "2183.3013619098224",
		liquidityUnits: "176433986039108",
		nativeDecimal: "18",
		poolAPY: "0",
		runeDepth: "616183576233050",
		saversAPR: "0.05791226796304939",
		saversDepth: "953885850663",
		saversUnits: "892575458977",
		status: "available",
		synthSupply: "1016592545188",
		synthUnits: "85438145829454",
		totalCollateral: "158914950037",
		totalDebtTor: "109954675065759",
		units: "261872131868562",
		volume24h: "485064295648368",
	},

	{
		annualPercentageRate: "-1.208383188826685",
		asset: "LTC.LTC",
		name: "LTC",
		assetDepth: "5379758252284",
		assetPrice: "13.049682567671423",
		assetPriceUSD: "72.0373799488381",
		liquidityUnits: "23932546249148",
		nativeDecimal: "8",
		poolAPY: "0",
		runeDepth: "70204137483117",
		saversAPR: "0.05922025289004655",
		saversDepth: "4277135160717",
		saversUnits: "3920173251716",
		status: "available",
		synthSupply: "4365203820180",
		synthUnits: "16338025284325",
		totalCollateral: "0",
		totalDebtTor: "0",
		units: "40270571533473",
		volume24h: "28736098970866",
	},
];

export default Cryptocurrencies;
