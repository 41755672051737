import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Homepage from "../screens/home/Homepage";
import Wallets from "../screens/wallet/Wallets";

function RouterPage() {
	return (
		<Router>
			<Header />
			<Routes>
				<Route exact path="/" element={<Homepage />} />
				<Route exact path="/wallets" element={<Wallets />} />
			</Routes>
			<Footer />
		</Router>
	);
}

export default RouterPage;
