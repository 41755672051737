import React, { useEffect } from "react";
import Whatis from "./component/Whatis";
import { Box } from "@mui/material";
import Network from "./component/Network";
import Providing from "./component/Providing";
import HeroSection from "./component/hEROsECTION";
import Infrastructure from "./component/Infrastructure";
import Features from "./component/Features";
import Upcoming from "./component/Upcoming";
import Media from "./component/Media";

function Homepage() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Box sx={{ display: "flex", flexDirection: "column", gap: 10 }}>
			<HeroSection />
			<Whatis />
			<Network />
			<Providing />
			<Infrastructure />
			<Features />
			<Upcoming />
			<Media />
		</Box>
	);
}

export default Homepage;
