import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import native from "../../../assets/native.svg";
import stream from "../../../assets/stream.svg";
import dual from "../../../assets/dual.svg";
import savers from "../../../assets/savers.svg";
import dex from "../../../assets/dex.svg";
import generic from "../../../assets/generic.svg";
import lending from "../../../assets/lending.svg";
import asstess from "../../../assets/asset.svg";

function Infrastructure() {
	return (
		<>
			<Container>
				<Box>
					<Typography variant="h2" align="center">
						Trust Minimized Infrastructure
					</Typography>
					<Typography variant="body1" align="center">
						THORChain was designed from first principles to minimize trust and
						become a scalable, economically secure cross-chain communication and
						settlement layer. Dozens of exchanges rely on THORChain to process
						swaps for their users & more.
					</Typography>
				</Box>

				<Box
					mt={5}
					sx={{
						display: "grid",
						gridTemplateColumns: { xs: "repeat(2, 1fr)", md: "repeat(4, 1fr)" },
						gap: 4,
						justifyContent: "center",
						width: "100%",
					}}>
					{Data.map((item, index) => (
						<DataBox key={index} icon={item.icon} text={item.text} />
					))}
				</Box>
			</Container>
		</>
	);
}

export default Infrastructure;

export const DataBox = ({ icon, text }) => (
	<Box sx={{ border: "1px solid #3E3E3E", p: 2 }}>
		<Box
			sx={{
				height: "50px",
				width: "50px",
			}}>
			<img src={icon} alt="" style={{ width: "100%", height: "100%" }} />
		</Box>
		<Typography mt={1} sx={{ fontWeight: 800, fontSize: "20px" }}>
			{text}
		</Typography>
	</Box>
);

const Data = [
	{
		icon: native,
		text: "Native Asset Swaps",
	},
	{
		icon: stream,
		text: "Streaming Swaps",
	},
	{
		icon: dual,
		text: "Dual Liquidity",
	},
	{
		icon: savers,
		text: "Savers Vaults",
	},
	{
		icon: dex,
		text: "DEX Aggregation",
	},
	{
		icon: generic,
		text: "Generic Messaging",
	},
	{
		icon: lending,
		text: "Lending",
	},
	{
		icon: asstess,
		text: "Synthetic Assets",
	},
];
