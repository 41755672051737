import { createTheme } from "@mui/material";

export const theme = createTheme({
	palette: {
		primary: {
			main: "#aaaa",
		},
		secondary: {
			main: "#2CBE8C",
			dark: "#28f4af",
		},

		text: {
			primary: "#ffff",
		},
		grey: {
			main: "rgba(0, 0, 0, 0.48)",
			dark: "#A0A0A0",
		},
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1120,
			xl: 1536,
		},
	},
	typography: {
		fontFamily: "Manrope",
		h1: {
			fontSize: "80px",
			fontWeight: 800,
			color: "#fff",
			"@media screen and (max-width: 768px)": {
				fontSize: "48px",
			},
		},
		h2: {
			fontSize: "60px",
			fontWeight: 800,
			lineHeight: "70px",
			color: "#fff",
			"@media screen and (max-width: 768px)": {
				fontSize: "32px",
				lineHeight: "40px",
			},
		},
		h3: {
			fontSize: "40px",
			fontWeight: 800,
			lineHeight: "50px",
			color: "#fff",
		},
		h4: {
			fontSize: "20px",
			fontWeight: 800,
			lineHeight: "30px",
			color: "#fff",
		},
		h5: {
			fontSize: "20px",
			fontWeight: 500,
			color: "#aaaaaa",
			lineHeight: "34px",
		},
		h6: {
			fontSize: "20px",
			fontWeight: 700,
			color: "#fff",
			lineHeight: "34px",
		},
		body1: {
			fontSize: "20px",
			fontWeight: 400,
			color: "#fff",
			lineHeight: "34px",
		},
		body2: {
			fontSize: "16px",
			fontWeight: 400,
			color: "#fff",
		},

		caption: {
			fontSize: "14px",
			fontWeight: 700,
			color: "#28f4af",
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: "none",
					padding: "11px 40px",
					borderRadius: "5px",
					boxShadow: "none",
					fontSize: "16px",
					fontWeight: 700,
				},
				contained: {
					color: "#fff",
					background: "#2CBE8C",

					"&:hover": {
						background: "#2CBE8C",
						color: "#fff",
					},
				},
				outlined: {
					border: "2px solid #2CBE8C",
					color: "#2CBE8C",
					background: "transparent",

					"&:hover": {
						background: "transparent",
						color: "#2CBE8C",
						border: "2px solid #2CBE8C",
					},
				},
				text: {
					color: "#2CBE8C",
					background: "transparent",
					border: "none",
					padding: 0,
					margin: 0,
					display: "flex",
					justifyContent: "end",
					"&:hover": {
						color: "#2CBE8C",
						background: "transparent",
						border: "none",
						padding: 0,
						margin: 0,
						display: "flex",
						justifyContent: "end",
					},
				},
			},
		},
	},
	spacing: 8,
});
