import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React from "react";
import Hero from "../../../assets/hero.png";
import { theme } from "../../../mui/theme";
import { useNavigate } from "react-router-dom";

const TEXTS = [
	" Swaps",
	" Savers",
	" Lending",
	" Liquidity",
	" Messaging",
	" Aggregation",
	" Infrastructure",
	" Exchange",
	" Settlement",
	" WithoutBridges",
];
function HeroSection() {
	const navigate = useNavigate();
	const [index, setIndex] = React.useState(0);

	React.useEffect(() => {
		const intervalId = setInterval(
			() => setIndex((index) => index + 1),
			3000 // every 3 seconds
		);
		return () => clearTimeout(intervalId);
	}, []);
	return (
		<Box
			sx={{
				minHeight: "90vh",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				pt: 8,
			}}>
			<Container>
				<Box
					sx={{ background: theme.palette.secondary.main }}
					mt={{ xs: 4, md: 0 }}
					mb={6}>
					<Typography variant="body2" sx={{ color: "#000" }} align="center">
						GAIA chain has been paused by the validators
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: { xs: "column-reverse", md: "row" },
						justifyContent: { xs: "center", md: "space-between" },
						alignItems: "center",
					}}>
					<Stack width="100%" spacing={2} direction="column">
						<Typography
							variant="h1"
							sx={{ display: { xs: "none", md: "flex" } }}>
							Cross - Chain
							{TEXTS[index % TEXTS.length]}
						</Typography>
						<Typography
							variant="h1"
							align="center"
							sx={{ display: { xs: "flex", md: "none" } }}>
							Cross - Chain
							{TEXTS[index % TEXTS.length]}
						</Typography>
						<Typography
							variant="body2"
							sx={{ color: "#aaa", display: { xs: "none", md: "flex" } }}>
							Swap, Borrow, and Earn with your native assets. Powered by RUNE
						</Typography>
						<Typography
							variant="body2"
							align="center"
							sx={{ color: "#aaa", display: { xs: "flex", md: "none" } }}>
							Swap, Borrow, and Earn with your native assets. Powered by RUNE
						</Typography>

						<Stack spacing={2} direction={{ xs: "column", md: "row" }}>
							<Button onClick={() => navigate("/wallets")} variant="contained">
								Swap
							</Button>
							<Button onClick={() => navigate("/wallets")} variant="outlined">
								Earn
							</Button>
							<Button onClick={() => navigate("/wallets")} variant="outlined">
								Borrow
							</Button>
							<Button onClick={() => navigate("/wallets")} variant="outlined">
								Rectify
							</Button>
						</Stack>
					</Stack>

					<Box
						sx={{
							width: "100%",
							height: "100%",
							maxHeight: { xs: "280px", md: "100%" },
							maxWidth: { xs: "280px", md: "100%" },
						}}>
						<img
							src={Hero}
							alt=""
							style={{ width: "100%", height: "100%", objectFit: "cover" }}
						/>
					</Box>
				</Box>
			</Container>
		</Box>
	);
}

export default HeroSection;
