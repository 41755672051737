import { ThemeProvider } from "@mui/material";
import { theme } from "./mui/theme";
import RouterPage from "./router/RouterPage";

function App() {
	return (
		<ThemeProvider theme={theme}>
			<RouterPage />
		</ThemeProvider>
	);
}

export default App;
