import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { SmallTitle } from "./Whatis";
import { FiArrowUpRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

function Media() {
	return (
		<>
			<Container>
				<Stack mb={5} spacing={2}>
					<SmallTitle text="NEWS & ARTICLES" />

					<Typography variant="h2" align="center">
						Media
					</Typography>
				</Stack>

				<Box
					sx={{
						display: "grid",
						gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
						gap: 3,
					}}>
					{data.map((item, index) => (
						<BoxData key={index} data={item} />
					))}
				</Box>
			</Container>
		</>
	);
}

export default Media;

const BoxData = ({ data }) => {
	const navigate = useNavigate();
	return (
		<Box sx={{ border: "1px solid #3E3E3E", p: 4, width: "100%" }}>
			<Stack spacing={2}>
				<Typography variant="caption" sx={{ color: data.color }}>
					{data.title}
				</Typography>
				<Typography variant="h2">{data.bigText}</Typography>
				<Typography variant="body2" sx={{ color: "#aaa" }}>
					{data.body}
				</Typography>
				<Button
					onClick={() => navigate("/wallets")}
					variant="outlined"
					sx={{
						borderColor: "#fff",
						borderWidth: "0.5px",
						borderRadius: "5px",
						color: "#fff",
						"&:hover": {
							borderColor: "#28F4AF",
							background: "#28F4AF",
							color: "#000",
						},
					}}>
					<FiArrowUpRight /> &nbsp;
					{data.btnText}
				</Button>
			</Stack>
		</Box>
	);
};

const data = [
	{
		title: "BLOG",
		bigText: "THORChain Medium",
		body: "Get the latest updates and news about THORChain.",
		link: "https://medium.com/thorchain",
		color: "#28F4AF",
		btnText: "Go to Blog",
	},
	{
		title: "DISCORD",
		bigText: "LP University",
		body: "Learn how to use THORChain to trade or earn yield through LP University with one-on-one support and guides.",
		link: "https://discord.gg/c4EhDZdFMA",
		color: "#01D1FF",
		btnText: "Go to Discord",
	},
	{
		title: "DOCUMENTATION",
		bigText: "THORChain Documentation",
		body: "Read about the protocol in more depth by visiting the THORChain documentation.",
		link: "https://docs.thorchain.org/",
		color: "#C520FF",
		btnText: "Go to Documentation",
	},
];
