import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { theme } from "../../mui/theme";

function Footer() {
	return (
		<>
			<Container>
				<Box
					sx={{
						borderTop: "1px solid #1C1C1C",
						pt: 8,
						mt: 5,
						pb: 5,
					}}>
					<Box
						sx={{
							display: "grid",
							gridTemplateColumns: {
								xs: "repeat(2, 1fr)",
								md: "repeat(4, 1fr)",
							},
							gap: 4,
						}}>
						<Box component="ul">
							<Typography variant="h4" mb={4}>
								THORChain
							</Typography>
							{thorchain.map((item, index) => (
								<li key={index}>
									<a href={item.link}>
										<Typography
											variant="body2"
											sx={{
												color: theme.palette.text.main,
												"&:hover": {
													color: "#fff",
												},
											}}>
											{item.name}
										</Typography>
									</a>
								</li>
							))}
						</Box>
						<Box component="ul">
							<Typography variant="h4" mb={4}>
								Develop
							</Typography>
							{develop.map((item, index) => (
								<li key={index}>
									<a href={item.link}>
										<Typography
											variant="body2"
											sx={{
												color: theme.palette.text.main,
												"&:hover": {
													color: "#fff",
												},
											}}>
											{item.name}
										</Typography>
									</a>
								</li>
							))}
						</Box>
						<Box component="ul">
							<Typography variant="h4" mb={4}>
								Community
							</Typography>
							{community.map((item, index) => (
								<li key={index}>
									<a href={item.link}>
										<Typography
											variant="body2"
											sx={{
												color: theme.palette.text.main,
												"&:hover": {
													color: "#fff",
												},
											}}>
											{item.name}
										</Typography>
									</a>
								</li>
							))}
						</Box>
						<Box component="ul">
							<Typography variant="h4" mb={4}>
								Explorers
							</Typography>
							{explorers.map((item, index) => (
								<li key={index}>
									<a href={item.link}>
										<Typography
											variant="body2"
											sx={{
												color: theme.palette.text.main,
												"&:hover": {
													color: "#fff",
												},
											}}>
											{item.name}
										</Typography>
									</a>
								</li>
							))}
						</Box>
					</Box>

					<Box
						mt={8}
						sx={{
							display: "flex",
							flexDirection: { xs: "column", md: "row" },
							justifyContent: { xs: "center", md: "space-between" },
							alignItems: "center",
							gap: 2,
						}}>
						<Typography
							variant="body1"
							sx={{ fontSize: "14px", color: "#636363" }}>
							All THORChain brand is public domain. No rights reserved.
						</Typography>

						<Box sx={{ display: "flex", gap: 3.5 }}>
							<Social
								icon={
									<svg
										className="w-5 h-5 hover:text-white hover:scale-105 transform transition duration-250"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										overflow="visible">
										<path
											d="M10.802 17.77a.703.703 0 1 1-.002 1.406.703.703 0 0 1 .002-1.406m11.024-4.347a.703.703 0 1 1 .001-1.406.703.703 0 0 1-.001 1.406m0-2.876a2.176 2.176 0 0 0-2.174 2.174c0 .233.039.465.115.691l-7.181 3.823a2.165 2.165 0 0 0-1.784-.937c-.829 0-1.584.475-1.95 1.216l-6.451-3.402c-.682-.358-1.192-1.48-1.138-2.502.028-.533.212-.947.493-1.107.178-.1.392-.092.62.027l.042.023c1.71.9 7.304 3.847 7.54 3.956.363.169.565.237 1.185-.057l11.564-6.014c.17-.064.368-.227.368-.474 0-.342-.354-.477-.355-.477-.658-.315-1.669-.788-2.655-1.25-2.108-.987-4.497-2.105-5.546-2.655-.906-.474-1.635-.074-1.765.006l-.252.125C7.78 6.048 1.46 9.178 1.1 9.397.457 9.789.058 10.57.006 11.539c-.08 1.537.703 3.14 1.824 3.727l6.822 3.518a2.175 2.175 0 0 0 2.15 1.862 2.177 2.177 0 0 0 2.173-2.14l7.514-4.073c.38.298.853.461 1.337.461A2.176 2.176 0 0 0 24 12.72a2.176 2.176 0 0 0-2.174-2.174"
											fill="currentColor"></path>
									</svg>
								}
								link="https://docs.thorchain.org/"
							/>
							<Social
								icon={
									<svg
										className="w-5 h-5 hover:text-white hover:scale-105 transform transition duration-250"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M12 23.0539L16.419 9.44998H7.58099L12 23.0539Z"
											fill="currentColor"></path>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M1.38749 9.44998L0.0449935 13.5862C-0.0147322 13.77 -0.0147542 13.9679 0.0449307 14.1517C0.104616 14.3355 0.220943 14.4956 0.377243 14.6092L12 23.0539L1.38749 9.44998Z"
											fill="currentColor"></path>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M1.38751 9.44998H7.58064L4.91926 1.26223C4.78239 0.840731 4.18614 0.840731 4.04926 1.26223L1.38751 9.44998Z"
											fill="currentColor"></path>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M22.6125 9.44998L23.955 13.5825C24.0147 13.7662 24.0147 13.9642 23.9551 14.148C23.8954 14.3317 23.779 14.4919 23.6227 14.6055L12 23.0539L22.6125 9.44998Z"
											fill="currentColor"></path>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M22.6125 9.45001H16.419L19.0808 1.25851C19.2176 0.837008 19.8139 0.837008 19.9508 1.25851L22.6125 9.45001Z"
											fill="currentColor"></path>
									</svg>
								}
								link="https://gitlab.com/thorchain"
							/>
							<Social
								icon={
									<svg
										className="w-5 h-5 hover:text-white hover:scale-105 transform transition duration-250"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<g clipPath="url(#clip0_217_2542)">
											<path
												d="M6.76884 5C10.5072 5 13.5374 8.05143 13.5374 11.8153C13.5374 15.5792 10.5069 18.6303 6.76884 18.6303C3.03073 18.6303 0 15.5792 0 11.8153C0 8.05143 3.0305 5 6.76884 5ZM17.5782 5.39922C19.4474 5.39922 20.9626 8.2715 20.9626 11.8153H20.9628C20.9628 15.3582 19.4476 18.2314 17.5784 18.2314C15.7092 18.2314 14.194 15.3582 14.194 11.8153C14.194 8.27242 15.709 5.39922 17.5782 5.39922ZM22.8097 6.0675C23.4669 6.0675 24 8.64083 24 11.8153C24 14.9888 23.4672 17.5631 22.8097 17.5631C22.1522 17.5631 21.6196 14.9895 21.6196 11.8153C21.6196 8.64106 22.1524 6.0675 22.8097 6.0675Z"
												fill="currentColor"></path>
										</g>
										<defs>
											<clipPath id="clip0_217_2542">
												<rect
													width="24"
													height="13.6875"
													fill="currentColor"
													transform="translate(0 5)"></rect>
											</clipPath>
										</defs>
									</svg>
								}
								link="https://medium.com/thorchain"
							/>
							<Social
								icon={
									<svg
										className="w-5 h-5 hover:text-white hover:scale-105 transform transition duration-250"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<g clipPath="url(#clip0_1131_7837)">
											<path
												d="M7.29138 14.6766V18.5416C7.29138 18.8116 7.46471 19.0508 7.72138 19.1358C7.78555 19.1566 7.85138 19.1666 7.91638 19.1666C8.11138 19.1666 8.29971 19.075 8.41971 18.9116L10.6805 15.835L7.29138 14.6766Z"
												fill="currentColor"></path>
											<path
												d="M19.7375 0.115907C19.5459 -0.0199262 19.2942 -0.0382595 19.0859 0.0709072L0.33587 9.86257C0.114204 9.97841 -0.0166296 10.2151 0.00170373 10.4642C0.0208704 10.7142 0.186704 10.9276 0.422537 11.0084L5.63504 12.7901L16.7359 3.29841L8.14587 13.6476L16.8817 16.6334C16.9467 16.6551 17.015 16.6667 17.0834 16.6667C17.1967 16.6667 17.3092 16.6359 17.4084 16.5759C17.5667 16.4792 17.6742 16.3167 17.7017 16.1342L19.9934 0.717574C20.0275 0.484241 19.9292 0.252574 19.7375 0.115907Z"
												fill="currentColor"></path>
										</g>
										<defs>
											<clipPath id="clip0_1131_7837">
												<rect width="20" height="20" fill="currentColor"></rect>
											</clipPath>
										</defs>
									</svg>
								}
								link="https://t.me/thorchain_org"
							/>
							<Social
								icon={
									<svg
										className="w-5 h-5 hover:text-white hover:scale-105 transform transition duration-250"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<g clipPath="url(#clip0_1131_7839)">
											<path
												d="M20 3.79875C19.2563 4.125 18.4637 4.34125 17.6375 4.44625C18.4875 3.93875 19.1363 3.14125 19.4412 2.18C18.6488 2.6525 17.7738 2.98625 16.8412 3.1725C16.0887 2.37125 15.0162 1.875 13.8462 1.875C11.5763 1.875 9.74875 3.7175 9.74875 5.97625C9.74875 6.30125 9.77625 6.61375 9.84375 6.91125C6.435 6.745 3.41875 5.11125 1.3925 2.6225C1.03875 3.23625 0.83125 3.93875 0.83125 4.695C0.83125 6.115 1.5625 7.37375 2.6525 8.1025C1.99375 8.09 1.3475 7.89875 0.8 7.5975C0.8 7.61 0.8 7.62625 0.8 7.6425C0.8 9.635 2.22125 11.29 4.085 11.6712C3.75125 11.7625 3.3875 11.8062 3.01 11.8062C2.7475 11.8062 2.4825 11.7913 2.23375 11.7362C2.765 13.36 4.2725 14.5538 6.065 14.5925C4.67 15.6838 2.89875 16.3412 0.98125 16.3412C0.645 16.3412 0.3225 16.3263 0 16.285C1.81625 17.4563 3.96875 18.125 6.29 18.125C13.835 18.125 17.96 11.875 17.96 6.4575C17.96 6.27625 17.9538 6.10125 17.945 5.9275C18.7588 5.35 19.4425 4.62875 20 3.79875Z"
												fill="currentColor"></path>
										</g>
										<defs>
											<clipPath id="clip0_1131_7839">
												<rect width="20" height="20" fill="currentColor"></rect>
											</clipPath>
										</defs>
									</svg>
								}
								link="https://twitter.com/thorchain"
							/>
						</Box>
					</Box>
				</Box>
			</Container>
		</>
	);
}

export default Footer;

const thorchain = [
	{
		name: "Documentation",
		link: "#",
	},
	{
		name: "Whitepapers",
		link: "#",
	},
	{
		name: "Dashboard",
		link: "#",
	},
	{
		name: "Audits",
		link: "#",
	},
	{
		name: "Medium",
		link: "#",
	},
];
const develop = [
	{
		name: "Documentation",
		link: "#",
	},
	{
		name: "Node Operators",
		link: "#",
	},
	{
		name: "Dev Discord",
		link: "#",
	},
	{
		name: "Bug Bounties",
		link: "#",
	},
	{
		name: "XChainJS",
		link: "#",
	},
];
const community = [
	{
		name: "Linktree",
		link: "#",
	},
	{
		name: "Twitter",
		link: "#",
	},
	{
		name: "LP University",
		link: "#",
	},
	{
		name: "Nine Realms",
		link: "#",
	},
	{
		name: "GRC Videos",
		link: "#",
	},
];
const explorers = [
	{
		name: "THORChain.net",
		link: "#",
	},
	{
		name: "Viewblock",
		link: "#",
	},
	{
		name: "THORMon",
		link: "#",
	},
	{
		name: "THORCharts",
		link: "#",
	},
	{
		name: "Infobot",
		link: "#",
	},
];

 const Social = ({ icon, link }) => {
	return (
		<>
			<a href={link} target="_blank" rel="noreferrer">
				<Box sx={{ color: "#636363" }}>{icon}</Box>
			</a>
		</>
	);
};
