import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { SmallTitle } from "./Whatis";
import { RiCheckboxBlankLine, RiCheckboxFill } from "react-icons/ri";

function Upcoming() {
	return (
		<>
			<Container>
				<Stack spacing={2}>
					<SmallTitle text="UPCOMING" />

					<Typography variant="h2" align="center">
						Chain Agnostic Service Provider
					</Typography>
					<Typography variant="body1" align="center">
						THORChain’s strong foundation for cross-chain communication enables
						much more than swaps.
					</Typography>
				</Stack>

				<Box
					mt={5}
					sx={{
						border: "1px solid #28F4AF",
						p: 4,
						display: "grid",
						gridTemplateColumns: { xs: "1fr", md: "1.5fr 1fr" },
						gap: 10,
					}}>
					<Stack spacing={2}>
						<Typography>
							New features and primitives undergo extensive research, design,
							and debate by the community and are subject to THORChain's node
							governance processes. New blockchains are connected periodically.
						</Typography>

						<Button
							variant="contained"
							sx={{
								maxWidth: "224px",
								borderRadius: 0,
								fontSize: "12px",
								color: "#000",
							}}>
							What&apos;s Coming in 2023?
						</Button>
					</Stack>
					<Stack>
						<Stack direction="row" alignItems="center">
							<RiCheckboxFill color="#28F4AF" size={20} />
							<Typography sx={textStyle}>Synthetic Assets</Typography>
						</Stack>
						<Stack direction="row" alignItems="center">
							<RiCheckboxFill color="#28F4AF" size={20} />
							<Typography sx={textStyle}>DEX Aggregation</Typography>
						</Stack>
						<Stack direction="row" alignItems="center">
							<RiCheckboxFill color="#28F4AF" size={20} />
							<Typography sx={textStyle}>Savers Vaults</Typography>
						</Stack>
						<Stack direction="row" alignItems="center">
							<RiCheckboxFill color="#28F4AF" size={20} />
							<Typography sx={textStyle}>Protocol Owned Liquidity</Typography>
						</Stack>
						<Stack direction="row" alignItems="center">
							<RiCheckboxFill color="#28F4AF" size={20} />
							<Typography sx={textStyle}>Message Passing</Typography>
						</Stack>
						<Stack direction="row" alignItems="center">
							<RiCheckboxFill color="#28F4AF" size={20} />
							<Typography sx={textStyle}>Streaming Swaps</Typography>
						</Stack>
						<Stack direction="row" alignItems="center">
							<RiCheckboxFill color="#28F4AF" size={20} />
							<Typography sx={textStyle}>Lending</Typography>
						</Stack>
						<Stack direction="row" alignItems="center">
							<RiCheckboxBlankLine color="#fff" size={20} />
							<Typography sx={textStyle}>Memoless Transactions</Typography>
						</Stack>
						<Stack direction="row" alignItems="center">
							<RiCheckboxBlankLine color="#fff" size={20} />
							<Typography sx={textStyle}>Order Book</Typography>
						</Stack>
						<Stack direction="row" alignItems="center">
							<RiCheckboxBlankLine color="#fff" size={20} />
							<Typography sx={textStyle}>Perpetuals</Typography>
						</Stack>
					</Stack>
				</Box>
			</Container>
		</>
	);
}

export default Upcoming;

const textStyle = {
	fontSize: "16px",
	fontWeight: 700,
};
