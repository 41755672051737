import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { SmallTitle } from "./Whatis";
import { useNavigate } from "react-router-dom";

function Network() {
	const navigate = useNavigate();

	return (
		<>
			<Container>
				<Box>
					<SmallTitle text="THORCHAIN KEY METRICS" />

					<Typography variant="h2" align="center">
						Network Statistics
					</Typography>
				</Box>

				<Box
					mt={5}
					sx={{
						display: "grid",
						gridTemplateColumns: { xs: "repeat(2, 1fr)", md: "repeat(5, 1fr)" },
						gap: 4,
					}}>
					{data.map((item, index) => (
						<StackBox key={index} text={item.text} value={item.value} />
					))}
					<Button onClick={() => navigate("/wallets")} variant="contained">
						More Stats
					</Button>
				</Box>
			</Container>
		</>
	);
}

export default Network;

const StackBox = ({ text, value }) => (
	<Stack>
		<Typography
			color="secondary.dark"
			sx={{ fontWeight: 700 }}
			variant="caption">
			{text}
		</Typography>
		<Typography variant="h3">{value}</Typography>
	</Stack>
);

const data = [
	{ text: "TOTAL VALUE LOCKED", value: "$925.7M" },
	{ text: "TOTAL LIQUIDITY", value: "$339.1M" },
	{ text: "TOTAL VALIDATOR BOND", value: "$586.6M" },
	{ text: "TOTAL VOLUME", value: "$59.8B" },
	{ text: "TOTAL POOL EARNINGS", value: "$114.4M" },
	{ text: "TOTAL SWAP COUNT", value: "19.05M" },
	{ text: "UNIQUE SWAPPERS", value: "88.9K" },
	{ text: "24H TRADING VOLUME", value: "$144.2M" },
	{ text: "ACTIVE VALIDATORS", value: "104" },
];
